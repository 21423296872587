@import "styles/config";

//color
$baseBlack: #231f20;
$accentDark: #ff8200;
$accentLight: #32b8df;
$background-bar: #e8e8e4;

$tooltipWidth: 100px;
$card-box-shadow: 0px 3px 7px #cecece;

.rc-tabs-content .rc-tabs-tabpane {
  overflow-x: hidden;
}

// header for marketplace
%header {
  .header {
    &-wrapper {
      box-shadow: none;
      border-bottom: 2px solid $baseBlack;
    }
    &-secondWrapper {
      &-content {
        background-color: white !important;
        &-logos {
          padding: 0.5rem 0rem;
          // when top RG icons are selected, what color should they be...
          &-selected {
            background-color: rgb(253, 230, 216) !important;
          }
          img {
            width: 3rem;
            height: 3rem;
            background-color: transparent;
            box-shadow: 0px 5px 10px rgba(207, 207, 207, 0.5);
          }
        }
      }
    }
  }
}

%location {
  .location {
    &-container {
      @include media-breakpoint-up(md) {
        margin: 0rem;
        padding-bottom: 0;
      }
      &--header {
        box-shadow: 0px 4px 4px rgba(95, 95, 95, 0.1) !important;
        padding-bottom: 0;
      }
      &--cards {
        padding: 13rem 0rem 5rem 0rem;
        .static-text {
          margin: 2rem 0 !important;
        }
      }
      &--footer {
        bottom: 0;
      }
    }
  }
}

%option {
  .option {
    &-lineText {
      padding: 0;
    }
  }
}

%restaurantsFilter {
  border-bottom: 2px solid $baseBlack;
  .restaurantsFilter-title {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

%locationCard {
  flex-shrink: 0;
  border-bottom: 2px solid $baseBlack !important;
  .locationCard {
    &-top {
      padding: 0rem;
    }
    &-button {
      margin-bottom: 1rem !important;
    }
    .locationCard-bottom-icon-content {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.deliveryForm {
  padding-top: 1rem;
}

.upsell {
  padding: 0 1.2rem;
  box-shadow: $card-box-shadow;
  z-index: 2;
  .title {
    padding-top: 0.5rem;
  }
}

%upsellItem {
  box-shadow: $card-box-shadow;
  .upsellItem-info-title {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

%menu {
  .menu {
    &-groups {
      padding-top: 1.5rem !important;
    }
    &-divider {
      height: 2px;
      background: black;
      margin: 0 2.5rem;
      @include media-breakpoint-down(sm) {
        margin: 0 1rem;
      }
    }
  }
}

%locationInfo {
  max-width: 30rem !important;
  .locationInfo-body {
    line-height: 1.5;
  }
}

.banner {
  //TODO: fix this in component library
  &-info {
    padding: 1rem 0;
    border-radius: 5px !important;

    //targets locationinfo title on market page
    &-content > span:nth-of-type(1) {
      font-size: 2rem !important;
      @include media-breakpoint-up(md) {
        margin-top: 0 !important;
      }
      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
      }
      text-align: center;
    }
  }
}

%menuGroup {
  .group {
    &-name {
      margin-left: 5px !important;
    }
    &-info {
      text-align: left;
      padding: 0 0 1rem 0;
      margin-top: 0.4rem !important;
      margin-bottom: 0.4rem !important;
    }
    &-items {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      /// takinga away each menu item's scroll-bar
      & > div {
        overflow-y: hidden;
      }
    }
  }
}

.menu-item .menu-item {
  box-shadow: $card-box-shadow;
}

.banner {
  &-info {
    padding: 1rem 0;
    border-radius: 5px;
    //targets locationinfo title on market page
    &-content > span:nth-of-type(1) {
      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
      }
      text-align: center;
      @media (max-width: 575.98px) {
        font-size: 1.2rem !important;
      }
    }
  }
}

%itemDetails-tabs {
  .itemDetails-tabs-nav-container {
    .nav {
      .nav-item {
        .item-button {
          padding: 0.5rem 1rem;
        }
        &[data-active="1"] {
          .item-button {
            background-color: $accentDark !important;
            span {
              color: white !important;
            }
          }
        }
      }
    }
  }
}

%itemDetails {
  .itemDetails {
    &-back {
      background: transparent !important;
      box-shadow: none;
      & > div {
        width: 2rem;
        height: 2rem;
      }
      img {
        width: 2rem;
        height: 2rem;
      }
    }
    &-footer {
      & > div:first-child {
        box-shadow: 0px -4px 4px rgba(95, 95, 95, 0.1) !important;
      }
      z-index: 1 !important;
    }
  }
}

.itemContainer {
  flex-basis: 33% !important;
  width: 33% !important;
}

// theming to ensure proper sizing of modifier options
%modifierItem {
  height: 12rem;
  box-shadow: $card-box-shadow;
  .item {
    &-title {
      @include media-breakpoint-down(sm) {
        font-size: 0.85rem;
        font-weight: 1200 !important;
      }
    }

    &-image {
      margin: 0 auto 0.25rem auto;
      max-height: 5rem;
      @include media-breakpoint-down(sm) {
        max-height: 3rem;
        width: 3rem;
      }
      border-radius: 100%;
    }

    &-wrapper {
      justify-content: space-around;
    }

    &-placeholder {
      width: 5rem;
      height: 5rem;
      @include media-breakpoint-down(sm) {
        width: 2.5rem;
        height: 2.5rem;
      }

      &-content {
        @include media-breakpoint-down(sm) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &-qty {
      height: 5rem !important;
      margin: 0 auto 0.25rem auto;
      @include media-breakpoint-down(sm) {
        height: 2.5rem;
      }
      &--number {
        @include media-breakpoint-down(sm) {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }

    &-price {
      margin-bottom: 0.25rem;
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    &-check {
      margin: 0 auto 0.5rem auto;
      &-icon {
        margin: 1rem auto;
        @include media-breakpoint-down(sm) {
          margin: 0rem auto;
          width: 3rem;
          height: 3rem;
        }
        img {
          width: 4rem;
          height: 4rem;
          @include media-breakpoint-down(sm) {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }
}

%order {
  .order {
    &-header {
      z-index: 1;
      box-shadow: 0px 4px 4px rgba(95, 95, 95, 0.1) !important;
    }
  }
}

%loyaltyHeader {
  padding: 0rem !important;
  .loyaltyHeader {
    &-image {
      display: none;
    }
    &-description {
      &-text {
        text-align: left;
      }
    }
  }
}

%packingItem {
  .packingItem {
    &-radio {
      label {
        width: 25px;
        height: 25px;
      }
    }
  }
}

%groupContainer {
  .item {
    box-shadow: $card-box-shadow;
  }
}

// underlines each RG section in the cart, per figma design.
%cart {
  .cart-content {
    background: none;
    &--items {
      border-bottom: 2px solid black;
    }
  }
}

%confirm {
  width: 85% !important;
  .dialogue {
    margin-bottom: 4rem !important;
  }
}

%socialButton {
  .btn {
    &.google {
      border: none;
      path {
        fill: $accentDark !important;
      }
    }
    &-content {
      color: $accentDark !important;
    }
  }
}

%socialButtons {
  button {
    border: 2px solid $accentDark;
    background-color: white;
    & > div {
      background-color: white;
      font-weight: 700;
      path {
        fill: $accentDark !important;
      }
    }
  }
  rect {
    fill: white !important;
  }
}

.gm-style {
  height: 94% !important;
}

%itemDetailsGroups {
  .itemDetailsGroups {
    &-item {
      & .image-container {
        & .image {
          object-fit: cover !important;
          height: 100% !important;
        }
      }
    }
  }
}

%loginSignup {
  .loginSignup {
    &-header {
      padding: 5rem 0 0 0;
      &-content {
        padding: 2rem;
        & > div {
          width: 100%;
          align-items: center;
          margin-bottom: 0;
          & > div {
            & > div {
              & > span {
                &:nth-of-type(1) {
                  font-size: 2rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
